import Date from "../../../../src/components/Post/date";
import PostsNavigation from "../../../../src/components/Post/posts-navigation";
import Newsletter from "../../../../src/components/Newsletter/newsletter";
import HeroPost from "../../../../src/components/Hero/hero-post";
import heroImg from "../../../../src/images/posts/003/form-is-there.svg";
import ModalSvg from "../../../../src/images/posts/003/modal.inline.svg";
import * as styles from "../../../../src/pages/posts/003-input-and-form.module.css";
import * as React from 'react';
export default {
  Date,
  PostsNavigation,
  Newsletter,
  HeroPost,
  heroImg,
  ModalSvg,
  styles,
  React
};