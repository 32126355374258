import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './input.module.css';

const Input = React.forwardRef((props, ref) => {
  const label = props.label;
  const inputProps = { ...props };
  delete inputProps.label;
  delete inputProps.error;

  inputProps.className = `${styles.input} ${props.className} ${
    props.error ? styles.hasError : ''
  }`;

  if (ref) {
    inputProps.ref = ref;
  }

  return (
    <label>
      <div className={styles.label}>{label}</div>
      <input {...inputProps} />
      {props.error ? <div className={styles.errorText}>{props.error}</div> : ''}
    </label>
  );
});

Input.defaultProps = {
  type: 'text',
};

Input.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.any,
};

Input.displayName = 'Input';

export default Input;
