import React, { Component } from 'react';
import Input from '../../Input/input';

import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import * as styles from './search.module.css';

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      count: 0,
      countThrottled: 0,
      countDebounced: 0,
      isLoading: false,
      isThrottledLoading: false,
      isDebouncedLoading: false,
      fakeApiTimeoutHandler: null,
      fakeApiTimeoutHandler2: null,
      fakeApiTimeoutHandler3: null,
    };

    this.search = this.search.bind(this);
    this.searchDebounced = this.searchDebounced.bind(this);
    this.searchThrottled = this.searchThrottled.bind(this);

    this.handleSearchDebounced = debounce(this.searchDebounced, 400);
    this.handleSearchThrottled = throttle(this.searchThrottled, 400);
  }

  search(event) {
    const handler = this._fakeApiCall(
      'isLoading',
      this.state.fakeApiTimeoutHandler
    );
    this.setState({
      searchText: event.target.value,
      count: this.state.count + 1,
      fakeApiTimeoutHandler: handler,
    });

    this.handleSearchDebounced();
    this.handleSearchThrottled();
  }

  searchDebounced() {
    const handler = this._fakeApiCall(
      'isDebouncedLoading',
      this.state.fakeApiTimeoutHandler2
    );
    this.setState({
      countDebounced: this.state.countDebounced + 1,
      fakeApiTimeoutHandler2: handler,
    });
  }

  searchThrottled() {
    const handler = this._fakeApiCall(
      'isThrottledLoading',
      this.state.fakeApiTimeoutHandler3
    );
    this.setState({
      countThrottled: this.state.countThrottled + 1,
      fakeApiTimeoutHandler3: handler,
    });
  }

  _fakeApiCall(loadingName, handler) {
    this.setState({ [loadingName]: true });

    if (handler) {
      clearTimeout(handler);
    }

    return setTimeout(() => this.setState({ [loadingName]: false }), 500);
  }

  render() {
    const {
      count,
      countDebounced,
      countThrottled,
      isLoading,
      isDebouncedLoading,
      isThrottledLoading,
      searchText,
    } = this.state;

    return (
      <div className="container">
        <div className={styles.searchContainer}>
          <Input
            label="Search"
            placeholder="Type something long..."
            type="search"
            name="search"
            autoComplete="off"
            onChange={this.search}
            value={searchText}
          />
        </div>

        <div className={styles.countBoxes}>
          <div className={styles.countBox}>
            <div className={styles.countBoxTitle}>No timing function</div>
            <div className={styles.countBoxNumber}>{count}</div>
            <div className={styles.countApi}>API calls</div>
          </div>
          <div className={styles.countBox}>
            <div className={styles.countBoxTitle}>Throttled</div>
            <div className={styles.countBoxNumber}>{countThrottled}</div>
            <div className={styles.countApi}>API calls</div>
          </div>
          <div className={styles.countBox}>
            <div className={styles.countBoxTitle}>Debounced</div>
            <div className={styles.countBoxNumber}>{countDebounced}</div>
            <div className={styles.countApi}>API calls</div>
          </div>

          <div className={styles.countBoxLoading}>
            {isLoading ? (
              <span className={styles.loading}>Loading...</span>
            ) : (
              searchText && <span>No results</span>
            )}
          </div>
          <div className={styles.countBoxLoading}>
            {isThrottledLoading ? (
              <span className={styles.loading}>Loading...</span>
            ) : (
              searchText && <span>No results</span>
            )}
          </div>
          <div className={styles.countBoxLoading}>
            {isDebouncedLoading ? (
              <span className={styles.loading}>Loading...</span>
            ) : (
              searchText && <span>No results</span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Search;
