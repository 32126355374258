import React, { useState } from 'react';
import * as styles from './sorting-colors.module.css';
import PropTypes from 'prop-types';

import {
  basicSorting,
  basicSortingWithTheSameFormat,
  noAlphaSorting,
  noAlphaSortingOnInts,
  oneDimensionSorting,
  sortWithClusters,
} from '../../../utils/sorting-colors';

const defaultColors = [
  '#ffffff',
  '#009cd4',
  '#505e75',
  'rgba(0,0,0,0.1)',
  '#606a79',
  'rgba(0,0,0,0.2)',
  '#1e2837',
  '#e3e6e9',
  '#969eac',
  '#f02913',
  '#eeeeee',
  '#555e74',
  '#000000',
  '#edeef1',
  '#02bd00',
  '#eae7e6',
  '#e3e3e3',
  '#f4f5f6',
  '#e6e6e6',
  'rgba(240,41,19,0.05)',
  '#efeeed',
  '#209bd0',
  '#1f2837',
  '#999999',
  'rgba(0,156,212,0.05)',
  'rgba(63,78,90,0.11)',
  'rgba(80,94,117,0.1)',
  '#868686',
  '#e8e4e1',
  '#fb7c00',
  '#485f79',
  '#e5f1f6',
  '#6e4888',
  '#b9b9b9',
  '#e0e2e8',
  'rgba(232,228,225,0.5)',
  '#f0f0f0',
  '#eaebee',
  '#656d78',
  'rgba(96,106,121,0.8)',
  '#ee0b0b',
  '#b3b3b3',
  'rgba(83,83,83,0.2)',
  '#f9f9f9',
  'rgba(80,94,117,0.5)',
  'rgba(255,255,255,0.1)',
  '#e9e9e9',
  '#f9f8f8',
  '#ff3ea8',
  'rgba(136,183,213,0)',
  '#dddddd',
  '#e0e0e0',
  '#c0c0c0',
  '#eef7fa',
  '#f5f4f3',
  'rgba(96,106,121,0.7)',
  '#adacac',
  '#e1e4e7',
  '#dadada',
  '#8891a7',
  'rgba(0,0,0,0.05)',
  '#fcfcfc',
  '#dcdcdc',
  '#535e73',
  'rgba(80,94,117,0.3)',
  '#9e9e9e',
  '#d4cfcf',
  '#f8d200',
  'rgba(194,225,245,0)',
  '#ffff00',
  '#928f8f',
  'rgba(0,0,0,0.5)',
  'rgba(0,156,212,0.2)',
  '#0295f7',
  '#5d99d0',
  'rgba(96,106,121,0.2)',
  'rgba(255,255,255,0.44)',
  '#dee0e2',
  '#c0c9d1',
  '#48cd35',
  '#5897fb',
  '#e4e4e4',
  '#333333',
  '#f7f6f6',
  '#acb1b5',
  '#e8e8e8',
  '#ff5f57',
  '#ffbe2f',
  '#28ca42',
  '#c9c9c9',
  '#cccccc',
  '#f3f4f5',
  '#e90e11',
  '#8b5ca9',
  '#a9a9a9',
  '#f2fafd',
  '#73468b',
  '#6b7897',
  'rgba(81,95,118,0.5)',
  'rgba(136,136,136,0.47)',
  '#dfdfdf',
  'rgba(158,158,158,0.2)',
  'rgba(2,189,0,0.2)',
  '#e2f2f7',
  'rgba(251,124,0,0.2)',
  '#616e82',
  '#1189ca',
  '#171e2a',
  'rgba(244,245,246,0.5)',
  'rgba(0,0,0,0.3)',
  '#e5f5fa',
  '#ffbc49',
  '#b8b8b8',
  '#c8c8c8',
  '#e5f5fb',
  'rgba(150,158,172,0.1)',
  '#949ead',
  '#59d2fb',
  '#00a9e7',
  '#f7f7f7',
  '#d9dce2',
  '#ecebeb',
  'rgba(0,0,0,0.13)',
  'rgba(101,116,139,0.07)',
  '#a8aeb9',
  'rgba(30,40,55,0.5)',
  '#09aae8',
  '#713996',
  '#fbfbfb',
  '#5ad1fc',
  '#4a4a4a',
  '#e9edf0',
  '#7ec0ee',
  '#f4d309',
];

const SortingColors = ({ sortingAlgo }) => {
  const [showClusters, setShowClusters] = useState(true);
  let colors = [...defaultColors];
  let clusters, clustersFiltered;

  if (sortingAlgo === 'basic') {
    colors = basicSorting(colors);
  } else if (sortingAlgo === 'basic-same-format') {
    colors = basicSortingWithTheSameFormat(colors);
  } else if (sortingAlgo === 'no-alpha') {
    colors = noAlphaSorting(colors);
  } else if (sortingAlgo === 'no-alpha-int') {
    colors = noAlphaSortingOnInts(colors);
  } else if (sortingAlgo && sortingAlgo.includes('hue')) {
    colors = oneDimensionSorting(colors, 'h', sortingAlgo.split('-')[1]);
  } else if (sortingAlgo && sortingAlgo.includes('saturation')) {
    colors = oneDimensionSorting(colors, 's', sortingAlgo.split('-')[1]);
  } else if (sortingAlgo && sortingAlgo.includes('lightness')) {
    colors = oneDimensionSorting(colors, 'l', sortingAlgo.split('-')[1]);
  } else if (sortingAlgo === 'clusters') {
    clusters = sortWithClusters(colors);
    colors = clusters.reduce((acc, curr) => {
      const colors = curr.colors.map((color) => color.hex);
      return [...acc, ...colors];
    }, []);

    clustersFiltered = clusters.filter((cluster) => cluster.colors.length > 0);
  }

  return (
    <div className="container">
      {sortingAlgo === 'clusters' && (
        <label className={styles.checkboxLabel}>
          <input
            type="checkbox"
            checked={showClusters}
            onChange={(event) => setShowClusters(event.target.checked)}
          />
          <span>Show clusters</span>
        </label>
      )}

      {sortingAlgo === 'clusters' && showClusters ? (
        <>
          {clustersFiltered.map((cluster, clusterIndex) => (
            <ul className={styles.colorsList} key={clusterIndex}>
              <li
                key="lead-color"
                className={styles.colorBox}
                style={{
                  backgroundColor: `rgb(${cluster.leadColor[0]},${cluster.leadColor[1]},${cluster.leadColor[2]})`,
                  height: '82px',
                  width: '82px',
                }}
                title={cluster.name}
              ></li>
              {cluster.colors.map((color, index) => (
                <li
                  key={index}
                  className={styles.colorBox}
                  style={{ backgroundColor: color.hex }}
                  title={color.hex}
                ></li>
              ))}
            </ul>
          ))}
        </>
      ) : (
        <ul className={styles.colorsList}>
          {colors.map((color, index) => (
            <li
              key={index}
              className={styles.colorBox}
              style={{ backgroundColor: color }}
              title={color}
            ></li>
          ))}
        </ul>
      )}
    </div>
  );
};

SortingColors.propTypes = {
  sortingAlgo: PropTypes.string,
};

export default SortingColors;
