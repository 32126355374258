// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-001-dynamic-partially-active-link-mdx": () => import("./../../../src/pages/posts/001-dynamic-partially-active-link.mdx" /* webpackChunkName: "component---src-pages-posts-001-dynamic-partially-active-link-mdx" */),
  "component---src-pages-posts-002-content-curation-mdx": () => import("./../../../src/pages/posts/002-content-curation.mdx" /* webpackChunkName: "component---src-pages-posts-002-content-curation-mdx" */),
  "component---src-pages-posts-003-input-and-form-mdx": () => import("./../../../src/pages/posts/003-input-and-form.mdx" /* webpackChunkName: "component---src-pages-posts-003-input-and-form-mdx" */),
  "component---src-pages-posts-004-effective-titles-mdx": () => import("./../../../src/pages/posts/004-effective-titles.mdx" /* webpackChunkName: "component---src-pages-posts-004-effective-titles-mdx" */),
  "component---src-pages-posts-005-powerful-start-mdx": () => import("./../../../src/pages/posts/005-powerful-start.mdx" /* webpackChunkName: "component---src-pages-posts-005-powerful-start-mdx" */),
  "component---src-pages-posts-006-search-with-typo-tolerance-mdx": () => import("./../../../src/pages/posts/006-search-with-typo-tolerance.mdx" /* webpackChunkName: "component---src-pages-posts-006-search-with-typo-tolerance-mdx" */),
  "component---src-pages-posts-007-highlight-text-in-javascript-mdx": () => import("./../../../src/pages/posts/007-highlight-text-in-javascript.mdx" /* webpackChunkName: "component---src-pages-posts-007-highlight-text-in-javascript-mdx" */),
  "component---src-pages-posts-008-throttle-vs-debounce-on-real-examples-mdx": () => import("./../../../src/pages/posts/008-throttle-vs-debounce-on-real-examples.mdx" /* webpackChunkName: "component---src-pages-posts-008-throttle-vs-debounce-on-real-examples-mdx" */),
  "component---src-pages-posts-009-anchors-for-headings-in-mdx-mdx": () => import("./../../../src/pages/posts/009-anchors-for-headings-in-mdx.mdx" /* webpackChunkName: "component---src-pages-posts-009-anchors-for-headings-in-mdx-mdx" */),
  "component---src-pages-posts-010-sorting-colors-in-js-mdx": () => import("./../../../src/pages/posts/010-sorting-colors-in-js.mdx" /* webpackChunkName: "component---src-pages-posts-010-sorting-colors-in-js-mdx" */),
  "component---src-pages-posts-011-radial-gauge-mdx": () => import("./../../../src/pages/posts/011-radial-gauge.mdx" /* webpackChunkName: "component---src-pages-posts-011-radial-gauge-mdx" */),
  "component---src-pages-posts-012-graphql-benchmarks-mdx": () => import("./../../../src/pages/posts/012-graphql-benchmarks.mdx" /* webpackChunkName: "component---src-pages-posts-012-graphql-benchmarks-mdx" */),
  "component---src-pages-sideprojects-js": () => import("./../../../src/pages/sideprojects.js" /* webpackChunkName: "component---src-pages-sideprojects-js" */),
  "component---src-pages-til-js": () => import("./../../../src/pages/til.js" /* webpackChunkName: "component---src-pages-til-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-tools-pendo-jzb-decoder-js": () => import("./../../../src/pages/tools/pendo-jzb-decoder.js" /* webpackChunkName: "component---src-pages-tools-pendo-jzb-decoder-js" */)
}

