import ResizableArea from "../../../../src/components/ResizableArea/resizable-area";
import Date from "../../../../src/components/Post/date";
import HeroPost from "../../../../src/components/Hero/hero-post";
import TwitterBox from "../../../../src/components/TwitterBox/twitter-box";
import PostsNavigation from "../../../../src/components/Post/posts-navigation";
import GraphQLResultsTable from "../../../../src/components/PostExtras/GraphQLResultsTable/graphql-results-table";
import * as styles from "../../../../src/pages/posts/011-radial-gauge.module.css";
import heroImg from "../../../../src/images/posts/012/graphql-benchmarks.svg";
import nestJsStateOfJs2023 from "../../../../src/images/posts/012/nestjs-state-of-js-2023.png";
import resultsGraph from "../../../../src/images/posts/012/performance-of-graphql-stacks.svg";
import sevenLevelsOfStack from "../../../../src/images/posts/012/7-levels-of-stack.png";
import sevenLevelsOfStack2x from "../../../../src/images/posts/012/7-levels-of-stack@2x.png";
import runtime from "../../../../src/images/posts/012/runtime.png";
import runtime2x from "../../../../src/images/posts/012/runtime@2x.png";
import library from "../../../../src/images/posts/012/library.png";
import library2x from "../../../../src/images/posts/012/library@2x.png";
import graphql from "../../../../src/images/posts/012/graphql.png";
import graphql2x from "../../../../src/images/posts/012/graphql@2x.png";
import typing from "../../../../src/images/posts/012/typing.png";
import typing2x from "../../../../src/images/posts/012/typing@2x.png";
import jit from "../../../../src/images/posts/012/jit.svg";
import opentelemetry from "../../../../src/images/posts/012/opentelemetry.svg";
import * as React from 'react';
export default {
  ResizableArea,
  Date,
  HeroPost,
  TwitterBox,
  PostsNavigation,
  GraphQLResultsTable,
  styles,
  heroImg,
  nestJsStateOfJs2023,
  resultsGraph,
  sevenLevelsOfStack,
  sevenLevelsOfStack2x,
  runtime,
  runtime2x,
  library,
  library2x,
  graphql,
  graphql2x,
  typing,
  typing2x,
  jit,
  opentelemetry,
  React
};