import React, { Component } from 'react';

import * as styles from './newsletter.module.css';
import Input from '../Input/input';
import Button from '../Button/button';

import TwitterIcon from '../../images/twitter-icon.inline.svg';

class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();

    this.state = {
      email: '',
      error: null,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.setFocusOnInput = this.setFocusOnInput.bind(this);
    this.subscribeToNewsletter = this.subscribeToNewsletter.bind(this);
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value, error: null });
  }

  setFocusOnInput() {
    if (!this.state.email) {
      this.inputRef.current.focus();
    }
  }

  subscribeToNewsletter(event) {
    event.preventDefault();

    const validationResult = this._validateEmail(this.state.email);

    if (!validationResult.valid) {
      const error =
        validationResult.reason === 'presence'
          ? 'Type email address'
          : 'Email address is incorrect';

      this.setState({ error });
    } else {
      this.setState({ error: null });

      document.forms.newsletter.submit();
    }
  }

  _validateEmail(email) {
    if (!email) {
      return { valid: false, reason: 'presence' };
    }

    // eslint-disable-next-line no-control-regex
    const pattern = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
    if (!pattern.exec(email)) {
      return { valid: false, reason: 'format' };
    }
    return { valid: true };
  }

  render() {
    return (
      <div className={styles.newsletterWrapper}>
        <div className={styles.newsletter} onMouseEnter={this.setFocusOnInput}>
          <div className={styles.newsletterTitle}>
            Pssst… do you know what’s cool?
          </div>

          <p className={styles.newsletterText}>
            A newsletter where the author doesn’t spam you and you can
            unsubscribe anytime if you are not interested anymore.{' '}
            <strong>I do not post that much </strong>
            (unfortunately) so if you found my content interesting then it would
            be a shame to miss it.{' '}
          </p>

          <form
            action="https://tinyletter.com/tomekdev"
            method="post"
            target="popupwindow"
            name="newsletter"
            onSubmit={this.subscribeToNewsletter}
            className={styles.newsletterForm}
            noValidate
          >
            <div className={styles.inputWrapper}>
              <Input
                label="Email"
                type="email"
                name="email"
                id="tlemail"
                ref={this.inputRef}
                error={this.state.error}
                value={this.state.email}
                onChange={this.handleEmailChange}
              />
              <input type="hidden" value="1" name="embed" />
            </div>
            <Button type="submit" className={styles.submitButton}>
              Subscribe
            </Button>
          </form>
        </div>
        <div className={styles.twitterLine}>
          <a
            href="https://twitter.com/tomekdev_"
            className={styles.twitterLink}
            rel="noreferrer"
            target="_blank"
            aria-label="tomekdev_ twitter account"
          >
            <TwitterIcon className={styles.twitterIcon} />
            <div className={styles.twitterText}>
              ...and/or follow me on Twitter
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default Newsletter;
