import React from 'react';
import PropTypes, { string } from 'prop-types';

import * as styles from './table-column-sort.module.css';
import SortIdle from '../../images/sort-idle.inline.svg';
import SortAsc from '../../images/sort-up.inline.svg';
import SortDesc from '../../images/sort-down.inline.svg';

const TableColumnSort = (props) => {
  const order = props.order;

  return (
    <button className={styles.button} onClick={props.onChange}>
      {order === 'idle' && <SortIdle className={styles.icon} />}
      {order === 'asc' && <SortAsc className={styles.icon} />}
      {order === 'desc' && <SortDesc className={styles.icon} />}
    </button>
  );
};

TableColumnSort.propTypes = {
  order: string,
  onChange: PropTypes.func,
};

export default TableColumnSort;
