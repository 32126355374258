import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RadialGaugeStep2Drawing from '../../Chart/RadialGaugeSteps/RadialGaugeStep2Drawing';
import * as styles from './RadialGaugeAdjustDegrees.module.css';
import { RadialGauge } from '../../Chart';

export default function RadialGaugeAdjustValue({
  showAdjuster,
  showTrackSizeAdjuster,
  step,
  useFinal,
}) {
  const [value, setValue] = useState(50);
  const [trackSizeDegrees, setTrackSizeDegrees] = useState(270);

  return (
    <div>
      {useFinal ? (
        <RadialGauge
          trackSizeDegrees={trackSizeDegrees}
          onValueChange={setValue}
          value={value}
        />
      ) : (
        <RadialGaugeStep2Drawing step={step} value={value} />
      )}

      {showTrackSizeAdjuster && (
        <div className={styles.rangeWrapper}>
          <input
            type="range"
            id="track-size"
            name="track-size"
            min="10"
            max="350"
            value={trackSizeDegrees}
            onChange={(event) =>
              setTrackSizeDegrees(parseInt(event.target.value))
            }
          />
          <label htmlFor="value">{trackSizeDegrees}°</label>
        </div>
      )}

      {showAdjuster && (
        <div className={styles.rangeWrapper}>
          <input
            type="range"
            id="value"
            name="value"
            min="0"
            max="100"
            value={value}
            onChange={(event) => setValue(parseInt(event.target.value))}
          />
          <label htmlFor="value">{value}%</label>
        </div>
      )}
    </div>
  );
}

RadialGaugeAdjustValue.defaultProps = {
  showAdjuster: true,
  showTrackSizeAdjuster: false,
  step: 2,
  useFinal: false,
};

RadialGaugeAdjustValue.propTypes = {
  showAdjuster: PropTypes.bool,
  showTrackSizeAdjuster: PropTypes.bool,
  step: PropTypes.number,
  useFinal: PropTypes.bool,
};
