// extracted by mini-css-extract-plugin
export var countBox = "scroll-module--count-box--1j_3_";
export var countBoxNumber = "scroll-module--count-box-number--2z4JA";
export var countBoxTitle = "scroll-module--count-box-title--3cBn6";
export var countBoxes = "scroll-module--count-boxes--2UcOg";
export var progressBar = "scroll-module--progress-bar--cNrSV";
export var progressBars = "scroll-module--progress-bars--1_mWv";
export var progressText = "scroll-module--progress-text--3EEKm";
export var scrollContainer = "scroll-module--scroll-container--2oqKh";
export var scrollInner = "scroll-module--scroll-inner--3LTxw";
export var scrollInnerPadding = "scroll-module--scroll-inner-padding--dmTwj";
export var scrollMe = "scroll-module--scroll-me--1Z-E8";