import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './MouseTrackFromCenter.module.css';

export default function MouseTrackFromCenter({ children }) {
  const svgRef = useRef(null);
  const [maxX, setMaxX] = useState(0);
  const [maxY, setMaxY] = useState(0);
  const [MousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  useLayoutEffect(() => {
    setMaxX(svgRef.current.clientWidth);
    setMaxY(svgRef.current.clientHeight);
  }, []);

  function handleMouseMove(ev) {
    const x = ev.pageX - ev.currentTarget.offsetLeft;
    const y = ev.pageY - ev.currentTarget.offsetTop;
    setMousePosition({ x, y });
    return true;
  }

  return (
    <div
      className={styles.mouseTrackFromCenterContainer}
      onMouseMove={(ev) => handleMouseMove(ev)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={styles.mouseTrackFromCenterSvg}
        ref={svgRef}
      >
        <circle fill="white" cx="50%" cy="50%" r="5" />
        <line
          x1="50%"
          y1="50%"
          x2={MousePosition.x}
          y2={MousePosition.y}
          stroke="white"
        />

        <line
          x1="0"
          y1={MousePosition.y}
          x2={maxX}
          y2={MousePosition.y}
          stroke="gray"
        />
        <line
          x1={MousePosition.x}
          y1="0"
          x2={MousePosition.x}
          y2={maxY}
          stroke="gray"
        />
      </svg>
      <div>{children}</div>
    </div>
  );
}

MouseTrackFromCenter.propTypes = {
  children: PropTypes.node,
};
