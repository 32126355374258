import React, { useState } from 'react';
import { RadialGaugeStep1Track } from '../../Chart';
import * as styles from './RadialGaugeAdjustDegrees.module.css';

export default function RadialGaugeAdjustDegrees() {
  const [value, setValue] = useState(270);
  return (
    <div>
      <RadialGaugeStep1Track step={3} trackSizeDegrees={value} />

      <div className={styles.rangeWrapper}>
        <input
          type="range"
          id="degrees"
          name="degrees"
          min="10"
          max="360"
          value={value}
          onChange={(event) => setValue(parseInt(event.target.value))}
        />
        <label htmlFor="degrees">{value}°</label>
      </div>
    </div>
  );
}
