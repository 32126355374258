import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './twitter-box.module.css';

const TwitterBox = ({ threadLink }) => (
  <div className={styles.twitterBox}>
    <div className={styles.twitterBoxTitle}>
      How to stay in touch? or comment?
    </div>

    <p className={styles.twitterBoxText}>
      If you'd like to see more content like this, please follow me on Twitter:{' '}
      <a
        href="https://twitter.com/tomekdev_"
        rel="noreferrer"
        target="_blank"
        aria-label="tomekdev_ twitter account"
      >
        tomekdev_
      </a>
      . I usually write about interfaces in the real life. So where the design
      meets implementation.
      {threadLink && (
        <>
          <br />
          Comments and discussion welcomed in{' '}
          <a
            href={threadLink}
            rel="noreferrer"
            target="_blank"
            aria-label="thread to comment"
          >
            this thread
          </a>
          .
        </>
      )}
    </p>
  </div>
);

TwitterBox.propTypes = {
  threadLink: PropTypes.string,
};

export default TwitterBox;
