import React, { useState } from 'react';

import * as styles from './graphql-results-table.module.css';
import TableColumnSort from '../../TableColumnSort/table-column-sort';

const RESULTS = [
  {
    name: 'nodejs-fastify-mercurius-jit',
    requests: '12754.2',
    latency: '77.84',
    throughput: '110.22',
  },
  {
    name: 'nodejs-fastify-mercurius-pothos-jit',
    requests: '12433.2',
    latency: '79.85',
    throughput: '107.45',
  },
  {
    name: 'deno-yoga-jit',
    requests: '12092.0',
    latency: '82.13',
    throughput: '104.21',
  },
  {
    name: 'bun-fastify-mercurius-jit',
    requests: '11745.8',
    latency: '84.56',
    throughput: '100.97',
  },
  {
    name: 'bun-yoga-jit',
    requests: '11057.8',
    latency: '89.84',
    throughput: '95.05',
  },
  {
    name: 'nodejs-yoga-jit',
    requests: '10941.2',
    latency: '90.81',
    throughput: '94.55',
  },
  {
    name: 'nodejs-fastify-mercurius-type-graphql-jit',
    requests: '9991.8',
    latency: '99.47',
    throughput: '86.35',
  },
  {
    name: 'nodejs-nestjs-fastify-mercurius-jit-deparentification',
    requests: '9904.6',
    latency: '100.34',
    throughput: '85.59',
  },
  {
    name: 'deno-fastify-mercurius-jit',
    requests: '9608.6',
    latency: '103.46',
    throughput: '82.81',
  },
  {
    name: 'nodejs-nestjs-fastify-mercurius-jit',
    requests: '7679.1',
    latency: '129.54',
    throughput: '66.36',
  },
  {
    name: 'bun-yoga',
    requests: '7501.2',
    latency: '132.62',
    throughput: '64.48',
  },
  {
    name: 'bun-fastify-mercurius',
    requests: '6784.0',
    latency: '146.67',
    throughput: '58.32',
  },
  {
    name: 'nodejs-express-yoga-jit',
    requests: '6765.6',
    latency: '147.03',
    throughput: '58.81',
  },
  {
    name: 'nodejs-fastify-mercurius',
    requests: '5786.2',
    latency: '172.00',
    throughput: '50.00',
  },
  {
    name: 'deno-yoga',
    requests: '5773.2',
    latency: '172.40',
    throughput: '49.75',
  },
  {
    name: 'nodejs-fastify-mercurius-pothos',
    requests: '5712.1',
    latency: '174.21',
    throughput: '49.36',
  },
  {
    name: 'nodejs-fastify-mercurius-type-graphql',
    requests: '5472.2',
    latency: '181.86',
    throughput: '47.29',
  },
  {
    name: 'nodejs-yoga',
    requests: '5443.6',
    latency: '182.81',
    throughput: '47.04',
  },
  {
    name: 'nodejs-yoga-type-graphql',
    requests: '5332.6',
    latency: '186.61',
    throughput: '46.08',
  },
  {
    name: 'bun-graphql-http',
    requests: '5319.1',
    latency: '187.08',
    throughput: '45.72',
  },
  {
    name: 'nodejs-fastify-mercurius-open-telemetry-irs',
    requests: '4976.9',
    latency: '198.96',
    throughput: '43.01',
  },
  {
    name: 'deno-fastify-mercurius',
    requests: '4828.4',
    latency: '206.12',
    throughput: '41.60',
  },
  {
    name: 'nodejs-express-yoga',
    requests: '4352.3',
    latency: '224.05',
    throughput: '37.83',
  },
  {
    name: 'nodejs-express-yoga-pothos',
    requests: '4124.5',
    latency: '235.19',
    throughput: '35.85',
  },
  {
    name: 'nodejs-koa-graphql-api',
    requests: '3925.4',
    latency: '245.21',
    throughput: '33.89',
  },
  {
    name: 'nodejs-nestjs-fastify-mercurius',
    requests: '3915.6',
    latency: '245.83',
    throughput: '33.83',
  },
  {
    name: 'nodejs-yoga-no-pav-cache',
    requests: '3913.3',
    latency: '245.96',
    throughput: '33.81',
  },
  {
    name: 'nodejs-graphql-http',
    requests: '3843.1',
    latency: '249.82',
    throughput: '33.27',
  },
  {
    name: 'nodejs-express-yoga-type-graphql',
    requests: '3625.2',
    latency: '262.74',
    throughput: '31.51',
  },
  {
    name: 'nodejs-graphql-http-type-graphql',
    requests: '3580.5',
    latency: '266.84',
    throughput: '31.00',
  },
  {
    name: 'nodejs-express-graphql-http',
    requests: '3157.2',
    latency: '293.71',
    throughput: '27.40',
  },
  {
    name: 'nodejs-express-graphql-http-type-graphql',
    requests: '3037.1',
    latency: '300.35',
    throughput: '26.36',
  },
  {
    name: 'nodejs-koa-apollo',
    requests: '2963.5',
    latency: '304.39',
    throughput: '25.81',
  },
  {
    name: 'nodejs-fastify-mercurius-open-telemetry-itrs',
    requests: '2772.8',
    latency: '314.48',
    throughput: '23.96',
  },
  {
    name: 'nodejs-express-yoga-no-pav-cache',
    requests: '2629.1',
    latency: '319.20',
    throughput: '22.85',
  },
  {
    name: 'nodejs-express-apollo',
    requests: '2612.8',
    latency: '321.22',
    throughput: '22.88',
  },
  {
    name: 'nodejs-express-apollo-pothos',
    requests: '2587.5',
    latency: '323.40',
    throughput: '22.66',
  },
  {
    name: 'nodejs-express-apollo-type-graphql',
    requests: '2478.8',
    latency: '316.60',
    throughput: '21.71',
  },
  {
    name: 'nodejs-nestjs-fastify-apollo',
    requests: '2467.7',
    latency: '311.04',
    throughput: '21.38',
  },
  {
    name: 'nodejs-express-apollo-open-telemetry-irs',
    requests: '2313.2',
    latency: '300.96',
    throughput: '20.26',
  },
  {
    name: 'nodejs-nestjs-express-apollo',
    requests: '2095.5',
    latency: '299.09',
    throughput: '18.29',
  },
  {
    name: 'nodejs-nestjs-express-apollo-open-telemetry',
    requests: '1553.0',
    latency: '377.26',
    throughput: '13.56',
  },
  {
    name: 'nodejs-fastify-mercurius-open-telemetry',
    requests: '751.6',
    latency: '462.82',
    throughput: '6.50',
  },
  {
    name: 'nodejs-express-apollo-open-telemetry-itrs',
    requests: '648.4',
    latency: '535.70',
    throughput: '5.68',
  },
  {
    name: 'nodejs-express-apollo-open-telemetry',
    requests: '641.5',
    latency: '526.25',
    throughput: '5.62',
  },
];

const DEFAULT_OPTION = '--all--';
const ENVS = [DEFAULT_OPTION, 'nodejs', 'deno', 'bun'];
const LIBS = [DEFAULT_OPTION, 'express', 'fastify', 'koa'];
const GQLS = [DEFAULT_OPTION, 'apollo', 'mercurius', 'yoga', 'graphql-http'];
const JITS = [DEFAULT_OPTION, 'no jit', 'with jit'];
const OBSERVABILITIES = [DEFAULT_OPTION, 'disabled', 'enabled'];

const GraphQLResultsTable = () => {
  const [order, setOrder] = useState('requests-desc');
  const [orderCol, orderDir] = order.split('-');

  const setColumnOrder = (fieldName) => {
    if (fieldName === orderCol) {
      if (orderDir === 'asc') {
        return setOrder(`${fieldName}-desc`);
      } else {
        return setOrder(`${fieldName}-asc`);
      }
    } else {
      if (fieldName === 'requests') {
        return setOrder(`${fieldName}-desc`);
      } else {
        return setOrder(`${fieldName}-asc`);
      }
    }
  };

  const [env, setEnv] = useState(DEFAULT_OPTION);
  const [lib, setLib] = useState(DEFAULT_OPTION);
  const [gql, setGql] = useState(DEFAULT_OPTION);
  const [jit, setJit] = useState(DEFAULT_OPTION);
  const [obs, setObs] = useState(DEFAULT_OPTION);

  let results = RESULTS;

  if (env !== DEFAULT_OPTION) {
    results = results.filter((res) => res.name.includes(env));
  }

  if (lib !== DEFAULT_OPTION) {
    results = results.filter((res) => res.name.includes(lib));
  }

  if (gql !== DEFAULT_OPTION) {
    results = results.filter((res) => res.name.includes(gql));
  }

  if (jit !== DEFAULT_OPTION) {
    results = results.filter((res) =>
      jit === 'with jit' ? res.name.includes('jit') : !res.name.includes('jit')
    );
  }

  if (obs !== DEFAULT_OPTION) {
    results = results.filter((res) =>
      obs === 'enabled'
        ? res.name.includes('telemetry')
        : !res.name.includes('telemetry')
    );
  }

  results = results.sort((a, b) => {
    let aValue = a[orderCol];
    let bValue = b[orderCol];

    if (['requests', 'latency', 'throughput'].includes(orderCol)) {
      aValue = parseFloat(aValue);
      bValue = parseFloat(bValue);
    }

    if (orderDir === 'asc') {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  return (
    <div className="container">
      <div className={styles.filtersBar}>
        <div className={styles.filter}>
          <label htmlFor="filter-env">Runtime:</label>
          <select
            id="filter-env"
            className={styles.select}
            value={env}
            onChange={(event) => {
              setEnv(event.target.value);
            }}
          >
            {ENVS.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.filter}>
          <label htmlFor="filter-lib">Library:</label>
          <select
            id="filter-lib"
            className={styles.select}
            value={lib}
            onChange={(event) => {
              setLib(event.target.value);
            }}
          >
            {LIBS.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.filter}>
          <label htmlFor="filter-gql">GraphQL:</label>
          <select
            id="filter-gql"
            className={styles.select}
            value={gql}
            onChange={(event) => {
              setGql(event.target.value);
            }}
          >
            {GQLS.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.filter}>
          <label htmlFor="filter-jit">JIT:</label>
          <select
            id="filter-jit"
            className={styles.select}
            value={jit}
            onChange={(event) => {
              setJit(event.target.value);
            }}
          >
            {JITS.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.filter}>
          <label htmlFor="filter-obs">Observability:</label>
          <select
            id="filter-obs"
            className={styles.select}
            value={obs}
            onChange={(event) => {
              setObs(event.target.value);
            }}
          >
            {OBSERVABILITIES.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th align="left">
                Stack (
                {RESULTS.length === results.length
                  ? 'all ' + RESULTS.length
                  : results.length + ' of ' + RESULTS.length}
                )
                <TableColumnSort
                  order={orderCol === 'name' ? orderDir : 'idle'}
                  onChange={() => setColumnOrder('name')}
                />
              </th>
              <th align="right">
                Requests/s
                <TableColumnSort
                  order={orderCol === 'requests' ? orderDir : 'idle'}
                  onChange={() => setColumnOrder('requests')}
                />
              </th>
              <th align="right">
                Latency (ms)
                <TableColumnSort
                  order={orderCol === 'latency' ? orderDir : 'idle'}
                  onChange={() => setColumnOrder('latency')}
                />
              </th>
              <th align="right">
                Throughput/Mb
                <TableColumnSort
                  order={orderCol === 'throughput' ? orderDir : 'idle'}
                  onChange={() => setColumnOrder('throughput')}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {results.length > 0 ? (
              results.map(function (result, index) {
                return (
                  <tr key={index}>
                    <td align="left">{result.name}</td>
                    <td align="right">
                      {parseFloat(result.requests).toFixed(1)}
                    </td>
                    <td align="right">
                      {parseFloat(result.latency).toFixed(1)}
                    </td>
                    <td align="right">
                      {parseFloat(result.throughput).toFixed(1)}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className={styles.tableNoResults} colSpan="4">
                  No results. Please change filtering.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GraphQLResultsTable;
