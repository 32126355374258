import React, { Component } from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import * as styles from './scroll.module.css';

class Scroll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      countThrottled: 0,
      countDebounced: 0,
      countRAF: 0,
      offsetHeight: 0,
      percentage: 0,
      percentageDebounced: 0,
      percentageThrottled: 0,
      percentageRAF: 0,
    };

    this.scrollArea = React.createRef();
    this.scrollInnerArea = React.createRef();

    this.scroll = this.scroll.bind(this);
    this.scrollDebounced = this.scrollDebounced.bind(this);
    this.scrollThrottled = this.scrollThrottled.bind(this);
    this.scrollRAF = this.scrollRAF.bind(this);

    const timingD = props.reading ? 100 : 400;
    let timingT = timingD;

    if (props.requestAnim) {
      timingT = 16;
    }

    this.handleScrollDebounced = debounce(this.scrollDebounced, timingD);
    this.handleScrollThrottled = throttle(this.scrollThrottled, timingT);
  }

  scroll() {
    let state = { count: this.state.count + 1 };

    if (this.props.reading) {
      const pos = this._calculateScrollPosition();
      state = { ...state, ...pos };

      if (this.props.requestAnim) {
        requestAnimationFrame(this.scrollRAF);
      }
    }

    this.setState(state);

    this.handleScrollDebounced();
    this.handleScrollThrottled();
  }

  scrollRAF() {
    let state = {
      countRAF: this.state.countRAF + 1,
    };
    const pos = this._calculateScrollPosition();
    state = {
      ...state,
      offsetHeight: pos.offsetHeight,
      percentageRAF: pos.percentage,
    };

    this.setState(state);
  }

  scrollDebounced() {
    let state = {
      countDebounced: this.state.countDebounced + 1,
    };

    if (this.props.reading) {
      const pos = this._calculateScrollPosition();
      state = {
        ...state,
        offsetHeight: pos.offsetHeight,
        percentageDebounced: pos.percentage,
      };
    }

    this.setState(state);
  }

  scrollThrottled() {
    let state = {
      countThrottled: this.state.countThrottled + 1,
    };

    if (this.props.reading) {
      const pos = this._calculateScrollPosition();
      state = {
        ...state,
        offsetHeight: pos.offsetHeight,
        percentageThrottled: pos.percentage,
      };
    }

    this.setState(state);
  }

  _calculateScrollPosition() {
    const state = {};
    let { offsetHeight } = this.state;

    if (!offsetHeight) {
      offsetHeight =
        this.scrollInnerArea.current.offsetHeight -
        this.scrollArea.current.offsetHeight;
      state.offsetHeight = offsetHeight;
    }
    state.percentage = (this.scrollArea.current.scrollTop / offsetHeight) * 100;
    state.percentage = state.percentage > 100 ? 100 : state.percentage;

    return state;
  }

  render() {
    const {
      count,
      countDebounced,
      countThrottled,
      countRAF,
      percentage,
      percentageDebounced,
      percentageThrottled,
      percentageRAF,
    } = this.state;
    const { reading, requestAnim } = this.props;

    return (
      <div className="container">
        <div
          className={styles.scrollContainer}
          onScroll={this.scroll}
          ref={this.scrollArea}
        >
          <div className={styles.scrollInner} ref={this.scrollInnerArea}>
            {reading && (
              <div className={styles.progressBars}>
                <div
                  className={styles.progressBar}
                  style={{ width: `${percentage}%` }}
                >
                  <div className={styles.progressText}>
                    No timing function
                    <small> ({count})</small>
                  </div>
                </div>
                {requestAnim && (
                  <div
                    className={styles.progressBar}
                    style={{ width: `${percentageRAF}%` }}
                  >
                    <div className={styles.progressText}>
                      requestAnimationFrame
                      <small> ({countRAF})</small>
                    </div>
                  </div>
                )}
                <div
                  className={styles.progressBar}
                  style={{ width: `${percentageThrottled}%` }}
                >
                  <div className={styles.progressText}>
                    Throttled
                    <small>
                      {' '}
                      ({countThrottled}) {requestAnim && <span>[16ms]</span>}
                    </small>
                  </div>
                </div>
                {!requestAnim && (
                  <div
                    className={styles.progressBar}
                    style={{ width: `${percentageDebounced}%` }}
                  >
                    <div className={styles.progressText}>
                      Debounced<small> ({countDebounced})</small>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className={styles.scrollMe}>↓ Scroll me ↓</div>

            {!reading && (
              <div className={styles.countBoxes}>
                <div className={styles.countBox}>
                  <div className={styles.countBoxTitle}>No timing function</div>
                  <div className={styles.countBoxNumber}>{count}</div>
                </div>
                <div className={styles.countBox}>
                  <div className={styles.countBoxTitle}>Throttled</div>
                  <div className={styles.countBoxNumber}>{countThrottled}</div>
                </div>
                <div className={styles.countBox}>
                  <div className={styles.countBoxTitle}>Debounced</div>
                  <div className={styles.countBoxNumber}>{countDebounced}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Scroll.defaultProps = {
  reading: false,
  requestAnim: false,
};

Scroll.propTypes = {
  reading: PropTypes.bool,
  requestAnim: PropTypes.bool,
};

export default Scroll;
