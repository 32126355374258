import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './caption.module.css';

const Caption = ({ children }) => <p className={styles.caption}>{children}</p>;

Caption.propTypes = {
  children: PropTypes.node,
};

export default Caption;
