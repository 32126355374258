// extracted by mini-css-extract-plugin
export var inputWrapper = "newsletter-module--inputWrapper--3GNfg";
export var newsletter = "newsletter-module--newsletter--1wr1M";
export var newsletterForm = "newsletter-module--newsletterForm--2uEpX";
export var newsletterText = "newsletter-module--newsletterText--1Md04";
export var newsletterTitle = "newsletter-module--newsletterTitle--3Epqn";
export var newsletterWrapper = "newsletter-module--newsletterWrapper--1caE_";
export var submitButton = "newsletter-module--submitButton--38LNS";
export var twitterIcon = "newsletter-module--twitterIcon--3xoli";
export var twitterLine = "newsletter-module--twitterLine--4lv1l";
export var twitterLink = "newsletter-module--twitterLink--xXcSN";
export var twitterText = "newsletter-module--twitterText--3dztp";