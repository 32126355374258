import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './textarea.module.css';

const Textarea = (props) => {
  const label = props.label;
  const inputProps = { ...props };
  delete inputProps.label;

  inputProps.className = styles.textarea;

  return (
    <label>
      <div className={styles.label}>{label}</div>
      <textarea {...inputProps}></textarea>
    </label>
  );
};

Textarea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default Textarea;
