import Date from "../../../../src/components/Post/date";
import HeroPost from "../../../../src/components/Hero/hero-post";
import TwitterBox from "../../../../src/components/TwitterBox/twitter-box";
import PostsNavigation from "../../../../src/components/Post/posts-navigation";
import heroImg from "../../../../src/images/posts/008/throttle-vs-debounce.svg";
import leadingVsTrailingImg from "../../../../src/images/posts/008/leading-vs-trailing-edge.svg";
import * as styles from "../../../../src/pages/posts/008-throttle-vs-debounce-on-real-examples.module.css";
import Search from "../../../../src/components/PostExtras/TimingFunctions/search";
import AutosaveRichEditor from "../../../../src/components/PostExtras/TimingFunctions/autosave-rich-editor";
import Scroll from "../../../../src/components/PostExtras/TimingFunctions/scroll";
import * as React from 'react';
export default {
  Date,
  HeroPost,
  TwitterBox,
  PostsNavigation,
  heroImg,
  leadingVsTrailingImg,
  styles,
  Search,
  AutosaveRichEditor,
  Scroll,
  React
};